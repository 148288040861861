import React from "react";

const Footer = () => {
  return (
    <footer className="w-11/12 md:w-[90%] lg:w-[70vw] flex flex-col mx-auto my-14 text-center md:text-left">
      <div>
        <ul className="flex flex-row flex-wrap justify-center md:justify-start">
          <li className="mb-3.5 px-1.5 md:pr-10 md:pl-0">
            <a
              href="https://github.com/gurjotsingh4398"
              target="_blank"
              rel="noopener noreferrer"
              className="p-1 text-lg font-semibold no-underline border-current hover:text-primary-color focus:text-primary-color hover:border-b-2 focus:border-b-2"
            >
              Github
            </a>
          </li>
          <li className="mb-3.5 px-1.5 md:pr-10 md:pl-0">
            <a
              href="https://www.linkedin.com/in/gurjotsingh4398/"
              target="_blank"
              rel="noopener noreferrer"
              className="p-1 text-lg font-semibold no-underline border-current hover:text-primary-color focus:text-primary-color hover:border-b-2 focus:border-b-2"
            >
              Linkedin
            </a>
          </li>
          <li className="mb-3.5 px-1.5 md:pr-10 md:pl-0">
            <a
              href="https://twitter.com/gurjotsingh4398"
              target="_blank"
              rel="noopener noreferrer"
              className="p-1 text-lg font-semibold no-underline border-current hover:text-primary-color focus:text-primary-color hover:border-b-2 focus:border-b-2"
            >
              Twitter
            </a>
          </li>
        </ul>
      </div>
      <small>&copy; {new Date().getFullYear()} Copyright Gurjot Singh</small>
    </footer>
  );
};

export default Footer;
